<template>
    <div id="face-recognition-details">
        <div class="vx-card p-6">
            <div class="vx-row">
                <div class="vx-col items-center mb-2">
                    <TitleScreen></TitleScreen>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-1/3 mt-3">
                    <label class="vs-input--label">{{ $t('menuFaceRecognition.branch') }} </label>
                    <span class="text-danger">*</span>
                    <v-select :disabled="this.isUpdate" class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                        <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-1/3 mt-2">
                    <label class="vs-input--label">Camera </label>
                    <span class="text-danger mr-5">*</span>
                    <vx-tooltip :title="$t('textCamera.instructionAiF')"
                                color="warning"
                    >
                        <feather-icon color="primary" style="vertical-align: bottom; width: 14px;color: #1aaa55"
                                      type="border"
                                      icon-pack="feather"
                                      icon="HelpCircleIcon"
                        >
                        </feather-icon>
                    </vx-tooltip>
                    <v-select :disabled="this.isUpdate"
                              v-model="selectedCamera"
                              :clearable="false"
                              :options="cameraOptions"
                              name="camera"
                    >
                        <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                    </v-select>
                    <span class="text-danger text-sm">{{ errors.first('camera') }}</span>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-2/3 mt-2">
                    <label class="vs-input--label">{{ $t('menuFaceRecognition.eventName') }} </label>
                    <span class="text-danger">*</span>
                    <vs-input class="w-full"
                              data-vv-validate-on="blur"
                              name="nameRestrictedarea"
                              :disabled="this.isUpdate"
                              v-model="name"
                              v-validate="'required'"/>
                    <span class="text-danger text-sm">{{ errors.first('nameRestrictedarea') }}</span>
                </div>
            </div>
            <div class="vx-row mt-5">
                <div class="vx-col w-full" v-if="isLoadWeekUse">
                    <span class="color">{{ $t('menuIntrusion.utilization') }}: </span>
                    <div>
                        <WeekComponent :selectTimes="this.weekUse"
                                       :nameEventBus="nameEventBusUse">
                        </WeekComponent>
                    </div>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="flex flex-wrap items-center justify-end">
                        <vs-button class="ml-auto mt-2"
                                   @click="saveChanges"
                                   :disabled="!validateForm">
                            {{ `${isUpdate ? this.$t('button.update') : this.$t('button.create')}` }}
                        </vs-button>
                        <vs-button class="ml-4 mt-2"
                                   type="border"
                                   color="warning"
                                   @click="$router.back()">{{ $t('button.back') }}
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import WeekComponent from "../../../components/week-time/WeekComponentNew";
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    data() {
        return {
            organizationBranchId: null,
            organizationBranchOptions: [],
            cameraOptions: [],
            faceRecognition: [],
            cameraId: null,
            isLoadWeekUse: false,
            id: null,
            name: null,
            isUpdate: false,
            weekUse: {
                arr0: [],
                arr1: [],
                arr2: [],
                arr3: [],
                arr4: [],
                arr5: [],
                arr6: []
            },
            nameEventBusUse: 'update-data-week-use',
        }
    },
    components: {
        WeekComponent,
        vSelect,
        TitleScreen
    },
    computed: {
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.organizationBranchId),
                    value: this.organizationBranchId
                }
            },
            async set(obj) {
                this.organizationBranchId = obj.value;
                this.isLoadWeekUse = true;
                await this.getFaceRecognition()
                await this.getCamera();
                if (!this.isUpdate) {
                    this.selectedCamera = this.cameraOptions[0];
                } else {
                    this.selectedCamera = this.cameraOptions.find(x => x.value == this.cameraId);
                }
            }
        },

        selectedCamera: {
            get() {
                if (this.cameraOptions.length > 0 && this.cameraId) {
                    let currentCam = this.cameraOptions.find(x => x.value == this.cameraId);
                    if(currentCam){
                        return {
                            label: this.cameraOptions.find(x => x.value == this.cameraId).label,
                            value: this.cameraId
                        }
                    }
                    return {
                        value: null,
                        label: '',
                    }
                }
            },
            set(obj) {
                if (obj)
                    this.cameraId = obj.value
            }
        },

        validateForm() {
            return !this.errors.any()
        },
    },
    async created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        if (branchList.length === 0) {
            return;
        }
        this.organizationBranchOptions = branchList.map(item => {
            return {value: item.id, label: item.organizationBranchName}
        });
        this.id = this.$route.query.id;
        if (this.id) {
            await this.findById();
            this.selectedOrganizationBranch = this.organizationBranchOptions.find(x => x.value === this.organizationBranchId);
        } else {
            this.selectedOrganizationBranch = this.organizationBranchOptions[0];
            this.isLoadWeekUse = true;
        }
    },
    methods: {
        findById() {
            return new Promise(resolve => {
                let url = "/face-recognition/find-by-id/" + this.id;
                this.$vs.loading();
                this.$crm.get(url).then((res) => {
                    this.$vs.loading.close();
                    let data = res.data;
                    this.isUpdate = true;
                    this.name = data.name;
                    this.weekUse = JSON.parse(data.timeUse);
                    this.cameraId = data.cameraId;
                    this.organizationBranchId = data.organizationBranchId;
                    this.isLoadWeekUse = true;
                    resolve(true);
                }).catch(err => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },

        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('textFaceRecognition.undefined');
        },

        getFaceRecognition() {
            return new Promise((resolve, reject) => {
                this.$vs.loading();
                this.$crm.get(`/face-recognition/find-all?organizationBranchId=${this.organizationBranchId}`).then((response) => {
                    this.$vs.loading.close();
                    if (response.data && response.data.length > 0) {
                        this.faceRecognition = response.data;
                    }
                    resolve()
                    this.$vs.loading.close();
                }).catch(() => {
                    reject()
                    this.$vs.loading.close();

                })
            })
        },

        getCamera() {
            this.cameraOptions = [];
            return new Promise((resolve, reject) => {
                let url = `/camera/find-by-active-ai-function?functionName=face-recognition&organizationBranchId=${this.organizationBranchId}`;
                this.$vs.loading();
                this.$crm.get(url).then((response) => {
                    if (response.data.length > 0) {
                        if (!this.isUpdate) {
                            let arrIdCamAlready = this.faceRecognition.map(el => el.cameraId);
                            let filterCameAlready = response.data.filter(el => !arrIdCamAlready.includes(el.id));
                            this.cameraOptions = filterCameAlready.map(el => {
                                return {
                                    value: el.id,
                                    label: el.cameraName
                                }
                            })
                        } else {
                            this.cameraOptions = response.data.map(el => {
                                return {
                                    value: el.id,
                                    label: el.cameraName
                                }
                            })
                        }
                    }
                    resolve(true);
                    this.$vs.loading.close();
                }).catch((err) => {
                    reject(false);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },

        async saveChanges(evt) {
            evt.preventDefault();
            if(!this.selectedOrganizationBranch){
                return this.$vs.notify({
                    title: this.$t('title.error'),
                    text: this.$t('textFaceRecognition.branchIsRequired'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            if(!this.selectedCamera){
                return this.$vs.notify({
                    title: this.$t('title.error'),
                    text: this.$t('textFaceRecognition.cameraIsRequired'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }

            let check = false;
            for (const [_, valueTime] of Object.entries(this.weekUse)) {
                if(valueTime.length > 0){
                    check = true;
                }
            }

            if(!check){
                return this.$vs.notify({
                    title: this.$t('title.error'),
                    text: this.$t('textFaceRecognition.timeRequired'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }

            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;
            let requestBody = {
                name: this.name,
                timeUse: JSON.stringify(this.weekUse),
                cameraId: this.cameraId,
                organizationBranchId: this.organizationBranchId
            };
            let url = this.isUpdate ? "/face-recognition/update" : "/face-recognition/create";
            if (this.isUpdate) requestBody.id = this.id;
            this.$vs.loading();
            this.$crm.post(url, requestBody).then(() => {
                this.$vs.notify({
                    text: this.isUpdate ? this.$t('textFaceRecognition.updateEventSuccess') : this.$t('textFaceRecognition.addEventSuccess'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
                this.$vs.loading.close();
                this.$router.push(`/user/face-recognition`);
            }).catch(err => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: this.$t('title.error'),
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.$eventBus.$on(this.nameEventBusUse, (data) => {
            this.weekUse = data.data;
        });
    },
}
</script>
